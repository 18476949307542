import GlobalStyles from "../../assets/styles/GlobalStyles";
import Layout from "../../common/layout";
import Header from "../../components/section/header/main/Header";
import Banner from "../../components/section/banner";
import About from "../../components/section/about";
import RoadMap from "../../components/section/roadMap";
import Footer from "../../components/section/footer";
import Tokenomics from "../../components/section/tokenomics";

const Home = () => {
  
  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <Banner/>
        <About />
        {/* <Tokenomics />
        <RoadMap />
        <Footer /> */}
      </Layout> 
    </>
  );
};

export default Home;
