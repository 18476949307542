import styled from "styled-components";

const TokenomicsStyleWrapper = styled.section`
  position: relative;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 60%;
    width: 60%; 
    transform: translate(-50%, -50%);
    content: "";
  }

  /** Tokenomics items */
  .tokenomics_item {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
    img {
      width: 80%
    }
  }

  .reponsive {
    marginTop: 5px;
    img {
      width: 100%
    }
  }
  @media only screen and (max-width: 991px) {
    &::before {
      width: 100%;
      height: 90%;
    }
  }
`;

export default TokenomicsStyleWrapper;
