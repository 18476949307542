 
import BannerV1Wrapper from "./Banner.style";
 

import Particle from "./Particles"
import home from "../../../assets/images/home.png";

const Banner = () => {
  
  return (
    <BannerV1Wrapper id="home">
      {/* <Particle /> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12 order-1 order-lg-0">
          <img src={home} alt="home" />
            </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
