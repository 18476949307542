
import AboutStyleWrapper from "./About.style";
import lotso from "../../../assets/images/introduction.png";

const About = () => {
  return (
    <AboutStyleWrapper id="introduction">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img src={lotso} alt="introduction" />
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
