import { useState } from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logo from "../../../../assets/images/logo.png";

import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button className="mobile_menu_close_btn" onClick={() => mobileMenuhandle()}>
            <BsXLg />
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#introducion">Introducion</a>
            </li>
            <li>
              <a href="#tokenomics">Tokenomics</a>
            </li>
            <li>
              <a href="#roadmap">Roadmap</a>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a href="https://twitter.com/lotsoinu" target="_blank">
            <FaTwitter />
          </a>
          <a href="https://t.me/lotsoinuglobal" target="_blank">
            <FaTelegram />
          </a>
        </div>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
